<template>
  <div class="signet" style="padding:40px 15px 60px;background:rgb(238, 240, 242);">
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    name:'signet'
  }
</script>
